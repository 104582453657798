import { Message } from 'element-ui';
export default {

    // 校验文件类型和文件大小
    checkFileAndSize(file,size,onlyIncludeFile){
        if (file.size > size * 1024 * 1024) {
            Message.warning(`文件不能超过${size}M`)
            return false
        }
        let type = 'file/'+file.name.substr(file.name.lastIndexOf(".")+1)
        // ,'doc','docx','xls','xlsx','mp4','ogg','flv','avi','wmv','rmvb'
        let includeFile = onlyIncludeFile?onlyIncludeFile:['png', 'jpg', 'pdf','jpeg','dwg','zip','rar'];
        if (!includeFile.includes(type.split('/')[1])) {
            Message.warning('请上传指定格式文件')
            return false
        }
        return true;
    },

    /**
     * 压缩图片
     *@param img 被压缩的img对象
     * @param mx 触发压缩的图片最大宽度限制
     * @param mh 触发压缩的图片最大高度限制
     * * @param type 压缩后转换的文件类型
     */
    compressImg(img,mx,mh,type) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const { width: originWidth, height: originHeight } = img;
            // 最大尺寸限制
            const maxWidth = mx;
            const maxHeight = mh;
            // 目标尺寸
            let targetWidth = originWidth;
            let targetHeight = originHeight;
            if (originWidth > maxWidth || originHeight > maxHeight) {
                if (originWidth / originHeight > 1) {
                    // 宽图片
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth))
                } else {
                    // 高图片
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight))
                }
            }
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            context.clearRect(0, 0, targetWidth, targetHeight);
            // 图片绘制
            context.drawImage(img, 0, 0, targetWidth, targetHeight);
            //  有些浏览器不支持canvas.toBlob方法
            if(canvas.toBlob){

                canvas.toBlob(function(blob) {
                    resolve(blob)
                }, type || 'image/png')
            }else{
                let binStr = atob(canvas.toDataURL(type).split(',')[1])
                let len = binStr.length;
                let arr = new Uint8Array(len);
                while (len--){
                    arr[len] = binStr.charCodeAt(len)
                }
                let result = new Blob([arr],{type:type||'image/png'})
                resolve(result)
            }

        })
    },

    // 压缩前将file转换成img对象
    readImg(file) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();
            reader.onload = function (e) {
                img.src = e.target.result
            };
            reader.onerror = function (e) {
                reject(e)
            };
            reader.readAsDataURL(file);
            img.onload = function () {
                resolve(img)
            };
            img.onerror = function (e) {
                reject(e)
            }
        })
    },

    upload(){

    },

    // 判断是否是普通图片 png,jpg,jpeg
    isCommonPicture(file){
        let url = file.url || file.fileUrl;
        let fileSuffix = url.substr(url.lastIndexOf('.'));
        return ['.png','.jpg','.jpeg'].includes(fileSuffix)
    }
}